import { apiGet, apiPost, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const state = () => ({
    unit: {
        id: null,
        name: '',
        country_id: '',
        unit_type_id: '',
        year: '',
        update_allowed_time: '',
        client: '',
        source_id: '',
        planned_oee: '',
        current_state: '',
        last_seen: '',
        last_online_at: '',
        lifetime_utilization_fraction: '',
    },

    useData: [],
    daily_expected_utilization: 0,
    grafRefreshKey: 0,
    connectivity_settings: {
        connection_type: 'default_connection_type',
        configuration: '',
    },
})

const mutations = {
    UPDATE_FORM_STATE(state, { newState }) {
        state.unit = newState
    },
    UPDATE_SELECT_OPTIONS(state, { name, options }) {
        state[name] = [...options]
    },
    UPDATE_UNIT_USE_DATA(state, { data }) {
        state.useData = _.flatten(data)
    },
    UPDATE_DAILY_EXPCTED_UTILIZATION(state, { value }) {
        state.daily_expected_utilization = value
    },
    UPDATE_GRAPH_REFRESH_KEY(state) {
        state.grafRefreshKey += 1
    },
    RESET_ITEM_STATE(state) {
        state.unit = {
            id: null,
            name: '',
            country_id: '',
            unit_type_id: '',
            year: '',
            update_allowed_time: '',
            client: '',
            source_id: '',
            planned_oee: '',
            current_state: '',
            last_seen: '',
            last_online_at: '',
            lifetime_utilization_fraction: '',
        }
    },
    UPDATE_CONNECTIVITY(state, { field, value }) {
        state.connectivity_settings[field] = value
    },
}

const actions = {
    updateUnitFields({ commit, state }, { fieldName, newValue }) {
        const unitCopy = Object.assign(state.unit, {})
        if (unitCopy.hasOwnProperty(fieldName)) {
            unitCopy[fieldName] = newValue
            commit('UPDATE_FORM_STATE', { newState: unitCopy })
        }
    },

    resetUnitUseData({ commit }) {
        commit('RESET_UNIT_USE_DATA')
    },

    async getItemData(
        { commit, dispatch, state },
        { itemId, vm, with_connectivity_settings = 0 }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(
                vm.route('unit.show', [itemId, with_connectivity_settings])
            )
            if (data === null) {
                return;
            }

            // special case backend sends integer, but requires string
            data.update_allowed_time = data.update_allowed_time.toString()

            if (with_connectivity_settings && 'connectivity_settings' in data) {
                commit('UPDATE_CONNECTIVITY', {
                    field: 'connection_type',
                    value: data.connectivity_settings.connection_type,
                })
                commit('UPDATE_CONNECTIVITY', {
                    field: 'configuration',
                    value: JSON.stringify(
                        data.connectivity_settings.configuration
                    ),
                })
            }

            Object.keys(data).forEach((el) => {
                if (el === 'daily_expected_utilization') {
                    commit('UPDATE_DAILY_EXPCTED_UTILIZATION', {
                        value: data.daily_expected_utilization,
                    })
                } else {
                    dispatch('updateUnitFields', {
                        fieldName: el,
                        newValue: data[el],
                    })
                }
            })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async updateUnit({ dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiPost(
                vm.route('unit.update', [state.unit.id]),
                { ...state.unit }
            )
            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: state.unit.name,
                    }),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            dispatch('hideLoader', null, { root: true })
            const notification = {
                type: 'error',
                text: vm.$t('notifications.error_refresh'),
            }

            dispatch('notification/add', notification, { root: true })
        }
    },

    async saveConnectivity({ dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiPost(
                vm.route('unit.update_connectivity', [state.unit.id]),
                { ...state.connectivity_settings }
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t(
                        'notifications.success_connectivity_settings_updated',
                        {
                            name: state.unit.name,
                        }
                    ),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fetchUnitUseData(
        { commit, dispatch },
        { vm, itemId, grouping = null }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            const groupingValue = grouping === '0' ? null : grouping
            const data = await apiGet(
                vm.route('unit.unit_use_data', [itemId, 0, groupingValue])
            )
            if (data === null) {
                return;
            }

            commit('UPDATE_UNIT_USE_DATA', {
                data,
            })

            commit('UPDATE_GRAPH_REFRESH_KEY')
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getGraphData: (state) => (multiplier) => {
        return state.useData.reduce((holder, current) => {
            if (holder.length === 0) {
                holder.push({ x: [], y: [] }, { x: [], y: [] })
            }
            holder[0].x.push(current.begin)
            holder[0].y.push(current.count)

            holder[1].x.push(current.begin)
            holder[1].y.push(state.daily_expected_utilization * multiplier)
            return holder
        }, [])
    },
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
