<template>
    <div
        v-if="isPersonPassed"
        class="kyc_person_card"
        :class="cardWrapperClasses"
        @click="handleCardClick"
        @mouseover="setPersonCardHover(true)"
        @mouseleave="setPersonCardHover(false)"
    >
        <h3 class="kyc_person_card__name">
            {{ person.first_name }}
            <br />
            {{ person.last_name }}
        </h3>

        <div class="kyc_person_card__divider">
            <div />
            <div
                class="kyc_person_card__edit-icon"
                :class="editIconHover && 'pointer'"
                @click.stop="editAuthPerson"
                @mouseover="editIconHover = true"
                @mouseleave="editIconHover = false"
            >
                <mdicon class="icon--white" name="account-edit" />
            </div>
        </div>

        <p class="kyc_person_card__row">
            <span><mdicon class="icon--color-primary" name="web" /></span>
            {{ country }}
        </p>

        <p
            v-if="isPassportUploaded"
            class="kyc_person_card__row"
            :class="passportRowHover && 'pointer'"
            @mouseover="passportRowHover = true"
            @mouseleave="passportRowHover = false"
            @click.stop="handlePassportDownload"
        >
            <span>
                <mdicon class="icon--color-primary" name="passport" />
            </span>
            {{ $t('kyc.passport_uploaded') }}
        </p>
        <p v-else class="kyc_person_card__row kyc_person_card__row--empty">
            <span>
                <mdicon class="icon--color-gray" name="passport" />
            </span>
            {{ $t('kyc.passport_not_uploaded') }}
        </p>
        <footer>{{ footerText }}</footer>
    </div>
    <div
        v-else
        class="kyc_person_card kyc_person_card--selectable kyc_person_card--add_new"
        @click="addAuthPerson"
    >
        <div class="kyc_person_card__add_new_icon">
            <mdicon class="icon--color-primary" :size="128" name="plus" />
        </div>
        <p class="kyc_person_card__add_new_label">{{ $t('kyc.add_new') }}</p>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'AuthorizedPersonCard',

    props: {
        person: {
            required: true,
            type: Object,
        },

        selectedAuthPeopleArray: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            cardHover: false,
            passportRowHover: false,
            editIconHover: false,
        }
    },

    computed: {
        country() {
            if (this.person.country_id !== null) {
                const { countryOptions } = this.$store.state.country

                const country = countryOptions.filter((countryObject) => {
                    const countryId =
                        countryObject.value === undefined
                            ? countryObject.key
                            : countryObject.value

                    return Number(countryId) === Number(this.person.country_id)
                })[0]

                return country && country.label ? country.label : ''
            }

            return ''
        },

        isPassportUploaded() {
            return (
                this.person.passport_file_path !== null &&
                this.person.passport_file_path !== ''
            )
        },

        // if card is used only to display person and there is no select option
        // selectedAuthPeopleArray is not passed
        isPersonSelectable() {
            return Array.isArray(this.selectedAuthPeopleArray)
        },

        isPersonPassed() {
            return this.person.id !== undefined
        },

        footerText() {
            if (this.editIconHover) {
                return this.$t('kyc.edit_person')
            }

            if (this.passportRowHover) {
                return this.$t('kyc.download_passport')
            }

            if (this.isPersonSelectable && this.cardHover && !this.selected) {
                return this.$t('kyc.select_person')
            }

            return ''
        },

        selected() {
            if (
                Array.isArray(this.selectedAuthPeopleArray) &&
                this.selectedAuthPeopleArray.length > 0
            ) {
                return this.selectedAuthPeopleArray.includes(this.person.id)
            }

            return false
        },

        cardWrapperClasses() {
            return [
                this.isPersonSelectable && 'kyc_person_card--selectable',
                this.selected && 'kyc_person_card--selected',
            ]
        },
    },

    methods: {
        ...mapActions({
            openAuthorizedPersonModal: 'client/openAuthorizedPersonModal',
            downloadPassport: 'client/downloadPassport',
        }),

        handleCardClick() {
            this.$emit('auth-person-card-clicked', {
                id: this.person.id,
            })
        },

        handlePassportDownload() {
            this.downloadPassport({
                vm: this,
                personId: this.person.id,
                first_name: this.person.first_name,
                last_name: this.person.last_name,
            })
        },

        async editAuthPerson() {
            this.openAuthorizedPersonModal({ ...this.person })
        },

        async addAuthPerson() {
            this.openAuthorizedPersonModal({})
        },

        setPersonCardHover(value) {
            if (this.isPersonSelectable) {
                this.cardHover = value
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.kyc_person_card {
    width: 100%;
    background-color: rgba($vc-gray-light, 0.4);
    border: 2px solid rgba($vc-gray-light, 0.4);
    border-radius: 20px;
    box-shadow: 6px 10px 31px 9px rgba($vc-black, 0.1);
    margin-bottom: 50px;

    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    transition: 0.15s;

    &--selectable,
    .pointer {
        cursor: pointer;
    }

    &--selectable:hover,
    &--selected {
        box-shadow: none;
        border: 2px solid rgba($cs, 0.6);
    }

    &__name {
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 30px;
    }

    &__divider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        & > div:first-child {
            width: 25%;
            height: 6px;
            background: $cs;
            border-radius: 16px;
        }

        & .kyc_person_card__edit-icon {
            margin-left: auto;
            width: 48px;
            height: 48px;
            border-radius: 100px;
            background-color: $vc-gray;

            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.15;
        }

        & .kyc_person_card__edit-icon:hover {
            background-color: $cp;
        }
    }

    &__row {
        margin-top: 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        line-height: 1;

        &--empty {
            color: $vc-gray;
        }

        & > span {
            margin-right: 6px;
        }
    }

    & footer {
        height: 24px;
        margin-top: 10px;
        margin-bottom: -10px;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
    }

    &--add_new {
        justify-content: center;
    }

    &__add_new_icon {
        display: flex;
        justify-content: center;
    }

    &__add_new_label {
        text-align: center;
        font-size: 24px;
    }
}
</style>
