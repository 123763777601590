<template>
    <div class="core-dropdown-wrapper">
        <Dropdown
            v-model="value"
            :options="getOptions"
            :optionLabel="labelKeyName"
            :placeholder="placeholder"
            :filter="filter"
            :class="classes"
            :style="styles"
            :disabled="disabled"
            @hide="handleHide"
        />

        <InputNotification
            v-if="isInputNotificationShown"
            :validator="validator"
            :customNotificationMessage="customNotificationMessage"
        />
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import InputNotification from '@/components/InputNotification.vue'

export default {
    name: 'CoreDropdown',

    components: { Dropdown, InputNotification },

    props: {
        options: {
            type: Array,
            default: [],
        },

        labelKeyName: {
            type: String,
            default: 'label',
        },

        placeholder: {
            type: String,
            default: '',
        },

        filter: {
            type: Boolean,
            default: true,
        },

        width: {
            type: Number,
            default: null,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        validator: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        value: {
            get() {
                const selected = this.getOptions.filter(
                    (option) => option.value === this.$attrs.value
                )
                return selected.length === 1 ? selected[0] : ''
            },
            set(selected) {
                this.$emit('input', selected.value)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        classes() {
            return {
                'core-dropdown': true,
                'core-dropdown--full-width': this.fullWidth,
                'core-dropdown--condensed': this.condensed,
                'core-valid': this.isValid,
                'core-invalid': this.isInvalid,
                'core-warning': this.isWarning,
            }
        },

        isValid() {
            if (
                this.validator !== null &&
                !this.validator.$invalid &&
                this.validator.$dirty
            ) {
                return true
            }

            return false
        },

        isInvalid() {
            if (
                this.validator !== null &&
                this.validator.$invalid &&
                this.validator.$dirty
            ) {
                return true
            }

            return false
        },

        isWarning() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'warn'
            ) {
                return true
            }

            return false
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },

        getOptions() {
            return this.options.map((option) => {
                return {
                    value: option.value,
                    label: this.$t(option.label),
                }
            })
        },

        styles() {
            const stylesObject = {}

            if (this.width !== null && typeof this.width === 'number') {
                stylesObject.width = `${this.width}px`
            }

            return stylesObject
        },

        isInputNotificationShown() {
            if (this.customNotificationMessage !== null) return true

            if (this.validator && this.validator.$dirty) return true

            return false
        },
    },

    methods: {
        handleHide() {
            if (this.validator !== null) this.validator.$touch()
        },
    },
}
</script>

<style lang="scss">
.core-dropdown-wrapper {
    position: relative;
}

.core-dropdown {
    width: 100%;

    & > div.p-hidden-accessible {
        display: none;
    }

    & > .p-dropdown-label.p-inputtext {
        padding: 2.4rem 1.6rem;
    }

    & > .p-dropdown-trigger {
        margin: 0 1.6rem;
    }

    & .p-dropdown-label.p-inputtext.p-placeholder {
        color: $cs-25;
    }

    &--condensed > .p-inputtext {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }

    &--full-width {
        width: 100%;

        .p-inputtext {
            width: 100%;
        }
    }
}

.core-invalid {
    &.p-dropdown.p-component,
    &.p-dropdown.p-component:focus,
    &.p-dropdown.p-component:hover {
        border: 1px solid $color-error;
    }
}
.core-warning {
    &.p-dropdown.p-component,
    &.p-dropdown.p-component:focus,
    &.p-dropdown.p-component:hover {
        border: 1px solid $color-warning;
    }
}
.core-valid {
    &.p-dropdown.p-component,
    &.p-dropdown.p-component:focus,
    &.p-dropdown.p-component:hover {
        border: 1px solid $cp;
    }
}
</style>
