<template>
    <div class="client-item-modal-details">
        <CoreStringInput
            :value="inputs.name"
            @input="setInput('name', $event)"
            :validator="v$.name"
            :fullWidth="true"
            placeholder="§ui.placeholder_eq_operator_name"
            class="mb-3"
        />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.operator_industry') }}
                    {{ isFieldRequired('enduser_industry_id') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreDropdown
                    :value="inputs.enduser_industry_id"
                    @input="setInput('enduser_industry_id', $event)"
                    :validator="v$.enduser_industry_id"
                    :options="$store.state.industry.endUserIndustryOptions"
                    :placeholder="$t('§ui.placeholder_select_industry')"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                />
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.operator_location') }}
                    {{ isFieldRequired('country_id') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreDropdown
                    :value="inputs.country_id"
                    @input="setInput('country_id', $event)"
                    :validator="v$.country_id"
                    :options="$store.state.country.countryFinancingAvailableOptions"
                    :placeholder="$t('§ui.placeholder_select_country')"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <div class="flex-grow-1 flex-shrink-1 flex-basis-55" />
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.street"
                    @input="setInput('street', $event)"
                    :validator="v$.street"
                    placeholder="§ui.placeholder_eq_operator_street"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <div class="flex-grow-1 flex-shrink-1 flex-basis-55" />
            </template>

            <template #right>
                <div class="flex-grow-1 flex-shrink-1 flex-basis-45 flex">
                    <CoreStringInput
                        :value="inputs.postal_code"
                        @input="setInput('postal_code', $event)"
                        :validator="v$.postal_code"
                        placeholder="§ui.placeholder_eq_operator_postal"
                        class="flex-basis-40 client-item-modal-details__postal-code-input"
                    />
                    <CoreStringInput
                        :value="inputs.city"
                        @input="setInput('city', $event)"
                        :validator="v$.city"
                        placeholder="§ui.placeholder_eq_operator_city"
                        class="flex-basis-60"
                    />
                </div>
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.vat_identification_number') }}
                    {{ isFieldRequired('vat_identification_number') }}
                    &nbsp;&nbsp;&nbsp;
                    <span class="text-100 font-italic">
                        {{ $t('§eq_operator.optional') }}
                    </span>
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.vat_identification_number"
                    @input="setInput('vat_identification_number', $event)"
                    :validator="v$.vat"
                    placeholder="§ui.placeholder_input_vat_id_number"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                />
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreDropdown from '@/components/core/CoreDropdown'

const rules = {
    name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    enduser_industry_id: {
        required,
    },
    country_id: {
        required,
    },
    street: {
        required,
        maxLength: maxLength(200),
    },
    postal_code: {
        required,
        maxLength: maxLength(32),
    },
    city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    vat_identification_number: {
        maxLength: maxLength(25),
    },
}

export default {
    name: 'ClientItemModalDetails',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreDropdown,
    },

    data() {
        return { v$: {} }
    },

    computed: {
        inputs() {
            return this.$store.state.client.inputs
        },

        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules, this.$store.state.client.inputs)
        })
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_INPUT', { name, value })
        },
    },
}
</script>

<style lang="scss" scoped>
.client-item-modal-details {
    max-height: 72vh;
    overflow: auto;

    &__postal-code-input {
        width: 6.4rem;
        margin-right: 16px;
    }
}
</style>
