<template>
    <div class="contract-create-risk-modal">
        <div :key="refreshKey">
            <BaseTable>
                <template #table-body>
                    <div class="credit-risk-table__row">
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        />
                        <div
                            v-for="(value, colIndex) in creditRiskData.year"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(0, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.coface_lessee_rating !== null"
                        class="credit-risk-table__row"
                        ref="tableRow"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--gray"
                        >
                            {{ $t('contract.coface_lessee_rating') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.coface_lessee_rating"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(1, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="
                            creditRiskData.coface_one_year_probability_of_default_lessee !==
                            null
                        "
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        >
                            {{
                                $t(
                                    'contract.coface_one_year_probability_of_default_lessee'
                                )
                            }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.coface_one_year_probability_of_default_lessee"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(2, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ displayAsPercent(value, 4) }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData['debt-equity'] !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--gray"
                        >
                            {{ $t('contract.debt-equity') }}
                        </div>
                        <div
                            v-for="(value, colIndex) in creditRiskData[
                                'debt-equity'
                            ]"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(3, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.current_ratio !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        >
                            {{ $t('contract.current_ratio') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.current_ratio"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(4, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.net_profit_margin !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--gray"
                        >
                            {{ $t('contract.net_profit_margin') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.net_profit_margin"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(5, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.interest_coverage_ratio !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        >
                            {{ $t('contract.interest_coverage_ratio') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.interest_coverage_ratio"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(6, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.linx4_credit_risk_score !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--gray"
                        >
                            {{ $t('contract.linx4_credit_risk_score') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.linx4_credit_risk_score"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(7, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="
                            creditRiskData.linx4_credit_risk_category !== null
                        "
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        >
                            {{ $t('contract.linx4_credit_risk_category') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.linx4_credit_risk_category"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(8, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="creditRiskData.coface_rating_oem !== null"
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--gray"
                        >
                            {{ $t('contract.coface_rating_oem') }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.coface_rating_oem"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(9, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ value }}
                        </div>
                    </div>

                    <div
                        v-if="
                            creditRiskData.coface_one_year_probability_of_default_oem !==
                            null
                        "
                        class="credit-risk-table__row"
                    >
                        <div
                            class="credit-risk-table__name-cell credit-risk-table__name-cell--color"
                        >
                            {{
                                $t(
                                    'contract.coface_one_year_probability_of_default_oem'
                                )
                            }}
                        </div>
                        <div
                            v-for="(
                                value, colIndex
                            ) in creditRiskData.coface_one_year_probability_of_default_oem"
                            :key="`${value}-${colIndex}`"
                            :class="valueColumnClasses(0, colIndex)"
                            :style="valueColumnStyleObject(colIndex)"
                        >
                            {{ displayAsPercent(value, 4) }}
                        </div>
                    </div>
                </template>
            </BaseTable>
        </div>
        <div class="button">
            <BaseButton buttonClass="button--primary" @click="handleClose">
                {{ $t('ui.button_close') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ContractCreditRiskModal',

    data() {
        return {
            refreshKey: 0,
            valueColumnWidth: 0,
            firstScrollableCellMargin: 0,
        }
    },

    computed: {
        ...mapState('contract', {
            creditRiskData: (state) => state.contractData.credit_risk_table,
        }),
    },

    mounted() {
        this.setCellSizeValues()

        window.addEventListener('resize', this.updateTable)
    },

    unmounted() {
        window.removeEventListener('resize', this.updateTable)
    },

    methods: {
        handleClose() {
            this.$emit('close-modal')
        },

        valueColumnClasses(rowIndex, colIndex) {
            const classes = ['credit-risk-table__value-cell']

            rowIndex % 2 === 0
                ? classes.push('credit-risk-table__value-cell--color')
                : classes.push('credit-risk-table__value-cell--gray')

            if (colIndex === 0) {
                classes.push('credit-risk-table__value-cell--fixed')
            }
            if (colIndex === 1) {
                classes.push('credit-risk-table__value-cell--first-scrollable')
            }

            return classes
        },

        valueColumnStyleObject(colIndex) {
            if (colIndex === 1) {
                return {
                    width: this.valueColumnWidth,
                    marginLeft: this.firstScrollableCellMargin,
                }
            }

            return {
                width: this.valueColumnWidth,
            }
        },

        updateTable() {
            this.setCellSizeValues()
        },

        setCellSizeValues() {
            const ROW_WIDTH = this.$refs.tableRow.offsetWidth
            const ROW_PADDING = 40
            const NAME_CELL_WIDTH = 400
            const NUMBER_OF_VALUE_COLUMNS = this.creditRiskData.year.length

            const valueColumnWidth =
                (ROW_WIDTH - ROW_PADDING - NAME_CELL_WIDTH) /
                NUMBER_OF_VALUE_COLUMNS

            this.valueColumnWidth = `${valueColumnWidth}px`

            this.firstScrollableCellMargin = `${
                NAME_CELL_WIDTH + valueColumnWidth
            }px`
        },
    },
}
</script>

<style scoped lang="scss">
.contract-create-risk-modal {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    & > .button {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }
}

.credit-risk-table {
    &__row {
        display: flex;
        width: min-content;
        min-width: 100%;
        margin: 8px 0;
        padding: 8px 20px;
        background-color: #dbd3ec;
        border-radius: 20px;

        &--link {
            cursor: pointer;
        }

        &:nth-child(even) {
            background-color: #f3f3f4;
        }
    }

    &__name-cell {
        width: 400px;
        position: fixed;
        z-index: 1;

        margin-top: -8px;
        height: 39px;
        display: flex;
        align-items: center;
        border-radius: 20px 0 0 20px;

        &--color {
            background-color: #dbd3ec;
        }

        &--gray {
            background-color: #f3f3f4;
        }
    }

    &__value-cell {
        min-width: 150px;
        text-align: right;

        &--fixed {
            position: fixed;
            margin-left: 400px;
            z-index: 1;

            margin-top: -8px;
            min-width: 150px;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &::after {
                content: '   ';
                white-space: pre;
            }

            &.credit-risk-table__value-cell--color {
                background-color: #dbd3ec;
            }

            &.credit-risk-table__value-cell--gray {
                background-color: #f3f3f4;
            }
        }

        &--first-scrollable {
            margin-left: 600px;
        }
    }
}
</style>