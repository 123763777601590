<template>
    <div class="client-item-modal-contact-data">
        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.contact_person') }}
                    {{ isFieldRequired('contact_person') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.contact_person"
                    :validator="v$.contact_person"
                    :disabled="saving"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_contact_person"
                    @input="setInput('contact_person', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-5">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.contact_email') }}
                    {{ isFieldRequired('contact_email') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.contact_email"
                    :validator="v$.contact_email"
                    :disabled="saving"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_contact_email"
                    @input="setInput('contact_email', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <div class="client-item-modal-contact-data__buttons mb-3">
            <CoreLinkSecondary
                text="§ui_button.show_invoice_email"
                @click="handleInputToggle('showInvoicesEmailInput', 'invoice_email')"
            />

            <CoreLinkSecondary
                text="§ui_button.show_services_email"
                @click="handleInputToggle('showServicesEmailInput', 'maintenance_email')"
            />
        </div>

        <CoreTwoColumnsRow
            v-if="showInvoicesEmailInput"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.invoice_email') }}
                    {{ isFieldRequired('invoice_email') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.invoice_email"
                    :validator="v$.invoice_email"
                    :disabled="saving"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_invoice_email"
                    @input="setInput('invoice_email', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow
            v-if="showServicesEmailInput"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.maintenance_email') }}
                    {{ isFieldRequired('maintenance_email') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.maintenance_email"
                    :validator="v$.maintenance_email"
                    :disabled="saving"
                    placeholder="§ui.placeholder_input_maintenance_email"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    @input="setInput('maintenance_email', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_operator.phone') }}
                    &nbsp;&nbsp;&nbsp;
                    <span class="text-100 font-italic">
                        {{ $t('§eq_operator.optional') }}
                    </span>
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    :value="inputs.phone"
                    :validator="v$.phone"
                    :disabled="saving"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_phone"
                    @input="setInput('phone', $event)"
                />
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, email } from '@vuelidate/validators'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreLinkSecondary from '@/components/core/CoreLinkSecondary'

const rules = {
    contact_person: {
        required,
        maxLength: maxLength(100),
    },
    contact_email: {
        required,
        email,
    },
    invoice_email: { email },
    maintenance_email: { email },
    phone: {},
}

export default {
    name: 'ClientItemModalContactData',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreLinkSecondary,
    },

    props: {
        saving: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            showInvoicesEmailInput: false,
            showServicesEmailInput: false,
            v$: {},
        }
    },

    computed: {
        inputs() {
            return this.$store.state.client.inputs
        },

        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules, this.$store.state.client.inputs)
        })
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_INPUT', { name, value })
        },

        handleInputToggle(name, storeName) {
            this[name] = !this[name]
            this.setInput(storeName, '')
        },
    },
}
</script>

<style lang="scss" scoped>
.client-item-modal-contact-data {
    max-height: 72vh;
    overflow: auto;

    &__buttons {
        display: flex;
        gap: 3.2rem;
    }
}
</style>
