<template>
    <div class="core-currency-input-wrapper">
        <InputNumber
            v-model="value"
            mode="currency"
            :currency="currency"
            :locale="this.numberCurrencyDateFormat()"
            :inputStyle="{ 'text-align': 'right' }"
            :maxFractionDigits="maxFractionDigits"
            :class="classes"
            :disabled="disabled"
            :placeholder="placeholder"
            @focus="onFocus()"
            @blur="onBlur()"
        />
        <InputNotification
            v-if="isFocused"
            :validator="validator"
            :customNotificationMessage="customNotificationMessage"
        />
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'
import InputNotification from '@/components/InputNotification'
import { LOCALE_NAMES } from '@/helpers/localeOptions'

export default {
    name: 'CoreCurrencyInput',

    components: { InputNumber, InputNotification },

    props: {
        showBorder: {
            type: Boolean,
            default: true,
        },

        maxFractionDigits: {
            type: Number,
            default: 2,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        validator: {
            type: Object,
            default: null,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isFocused: false,
        }
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        value: {
            get() {
                const val = this.$attrs.value
                return isNaN(parseFloat(val)) ? val : parseFloat(val)
            },
            set(value) {
                this.$emit('input', value ? value.toString() : value)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        classes() {
            return {
                'core-currency-input': true,
                'p-inputnumber-input--no-border': !this.showBorder,
                'core-currency-input--full-width': this.fullWidth,
                'core-currency-input--condensed': this.condensed,
                'core-valid': this.isValid,
                'core-invalid': this.isInvalid,
                'core-warning': this.isWarning,
            }
        },

        isValid() {
            if (this.validator !== null && !this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isInvalid() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'error'
            ) {
                return true
            }

            if (this.validator !== null && this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isWarning() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'warn'
            ) {
                return true
            }

            return false
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },

        localeIsSet() {
            return (
                this.$store &&
                this.$store.state &&
                this.$store.state.user &&
                this.$store.state.user.user &&
                this.$store.state.user.user.preferred_locale
            )
        },

        currency() {
            if (
                this.localeIsSet &&
                this.$store.state.user.user.preferred_locale === LOCALE_NAMES.enGB
            ) {
                return 'GBP'
            }
            return 'EUR'
        },

        placeholder() {
            if (
                this.localeIsSet &&
                this.$store.state.user.user.preferred_locale === LOCALE_NAMES.enGB
            ) {
                return this.$t('§ui.placeholder_input_number_gbp')
            }
            return this.$t('§ui.placeholder_input_number_euro')
        },
    },

    methods: {
        onFocus() {
            this.isFocused = true
            this.$emit('focus')
        },

        onBlur() {
            this.isFocused = false
            this.$emit('blur')
        },
    },
}
</script>

<style lang="scss">
.core-currency-input-wrapper {
    position: relative;
}

.core-currency-input {
    & .p-inputtext.p-component.p-inputnumber-input::placeholder {
        color: $cs-25;
        pointer-events: none;
    }

    &--condensed > .p-inputtext {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }

    width: 100%;

    .p-inputtext {
        width: 100%;
    }
}

.p-inputnumber-input--no-border > .p-inputnumber-input {
    border: 0px;
    padding: 0;
}

.core-invalid {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-error;
        }
    }
}
.core-warning {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-warning;
        }
    }
}
.core-valid {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $cp;
        }
    }
}
</style>
