<template>
    <div class="main-content">
        <AuthorizedPersonCard
            v-for="person in authorizedPeople"
            :key="person.id"
            :person="person"
            :selectedAuthPeopleArray="selectedAuthPeopleArray"
            @auth-person-card-clicked="handleCardClicked($event)"
        />

        <AuthorizedPersonCard :person="{}" />
    </div>
</template>

<script>
import AuthorizedPersonCard from './AuthorizedPersonCard'

export default {
    name: 'AuthorizedPeopleSection',

    components: { AuthorizedPersonCard },

    props: {
        authorizedPeople: {
            type: Array,
            required: true,
        },

        selectedAuthPeopleArray: {
            type: Array,
            default: () => null,
        },
    },

    methods: {
        handleCardClicked({ id }) {
            this.$emit('auth-person-card-clicked', {
                id,
            })
        },
    },
}
</script>

<style>
</style>