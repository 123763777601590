<template>
    <div class="table__wrapper">
        <BaseTable>
            <template #table-header>
                <BaseTableHeader>
                    <div
                        class="table__colored-placeholder table__colored-placeholder--heading"
                    />
                    <div
                        class="table__colored-placeholder table__colored-placeholder--heading"
                    />
                    <div
                        class="table__colored-placeholder table__colored-placeholder--heading"
                    />
                </BaseTableHeader>
            </template>
            <template #table-body>
                <BaseTableRow v-for="row in numberOfRows" :key="`key-${row}`">
                    <div class="table__colored-placeholder" />
                    <div class="table__colored-placeholder" />
                    <div class="table__colored-placeholder" />
                </BaseTableRow>
            </template>
        </BaseTable>
    </div>
</template>

<script>
export default {
    name: 'SkeletonTable',
    data() {
        return {
            numberOfRows: 15,
        }
    },
}
</script>

<style scooped lang="scss">
.table__wrapper {
    padding: 30px 20px 0 20px;

    & .table__colored-placeholder {
        width: 200px;
        height: 24px;
        margin: 2px 0;
        margin-right: 200px;
        background: $cn-75;

        &--heading {
            background: $cn-50;
        }
    }
}
</style>