<template>
    <div class="unit-use-data-modal-wrapper">
        <div class="select-wrapper">
            <BaseSelect
                placeholder=""
                :options="groupingOptions"
                :value="grouping"
                @input="handleGroupingSelect($event)"
            />
        </div>
        <PaginationWrapper
            :key="refreshKey"
            :forceRefetch="true"
            :getRoute="getRoute"
        >
            <template v-slot:content="slotProps">
                <div class="table__wrapper">
                    <BaseTable>
                        <template #table-header>
                            <BaseTableHeader>
                                <BaseTableCell
                                    class="table__text"
                                    :cssClass="['bold', 'font-large']"
                                    width="200px"
                                >
                                    {{ $t('unit.use_type') }}
                                </BaseTableCell>
                                <BaseTableCell
                                    class="table__text"
                                    :cssClass="[
                                        'bold',
                                        'font-large',
                                        'align-right',
                                    ]"
                                    width="150px"
                                >
                                    {{ $t('unit.count') }}
                                </BaseTableCell>
                                <BaseTableCell
                                    class="table__text"
                                    :cssClass="[
                                        'bold',
                                        'font-large',
                                        'align-right',
                                    ]"
                                    width="150px"
                                >
                                    {{ $t('unit.expected_count') }}
                                </BaseTableCell>
                                <BaseTableCell
                                    v-if="grouping != '0'"
                                    class="table__text"
                                    :cssClass="[
                                        'bold',
                                        'font-large',
                                        'align-right',
                                    ]"
                                    width="150px"
                                >
                                    {{ $t('unit.relative_util') }}
                                </BaseTableCell>
                                <BaseTableCell
                                    class="table__text"
                                    :cssClass="[
                                        'bold',
                                        'font-large',
                                        'align-right',
                                    ]"
                                    width="300px"
                                >
                                    {{ $t('unit.begin') }}
                                </BaseTableCell>
                                <BaseTableCell
                                    class="table__text"
                                    :cssClass="[
                                        'bold',
                                        'font-large',
                                        'align-right',
                                    ]"
                                    width="300px"
                                >
                                    {{ $t('unit.duration') }}
                                </BaseTableCell>
                            </BaseTableHeader>
                        </template>
                        <template #table-body>
                            <BaseTableRow
                                v-for="row in slotProps.rows"
                                :key="row.id"
                            >
                                <BaseTableCell
                                    class="isLink"
                                    :cssClass="['font-large']"
                                    width="200px"
                                >
                                    {{
                                        row.type_name !== null
                                            ? row.type_name
                                            : ''
                                    }}
                                </BaseTableCell>
                                <BaseTableCell
                                    :cssClass="['font-large', 'align-right']"
                                    width="150px"
                                >
                                    {{ row.count }}
                                </BaseTableCell>
                                <BaseTableCell
                                    :cssClass="['font-large', 'align-right']"
                                    width="150px"
                                >
                                    {{ Math.round(row.planned) }}
                                </BaseTableCell>
                                <BaseTableCell
                                    v-if="grouping != '0'"
                                    :cssClass="['font-large', 'align-right']"
                                    width="150px"
                                >
                                    {{ displayAsPercent(row.relative_util) }}
                                </BaseTableCell>
                                <BaseTableCell
                                    :cssClass="['font-large', 'align-right']"
                                    width="300px"
                                >
                                    {{ getBeginTime(row.begin) }}
                                </BaseTableCell>
                                <BaseTableCell
                                    :cssClass="['font-large', 'align-right']"
                                    width="300px"
                                >
                                    {{ getDuration(row.begin, row.end) }}
                                </BaseTableCell>
                            </BaseTableRow>
                        </template>
                    </BaseTable>
                </div>
            </template>
        </PaginationWrapper>
        <div class="buttons">
            <BaseButton
                buttonClass="button--secondary"
                @click="handleClose"
            >
                {{ $t('ui.button_close') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { parseISO, format, formatDistanceStrict } from 'date-fns'
import en from 'date-fns/locale/en-IN'
import de from 'date-fns/locale/de-AT'
import PaginationWrapper from '@/components/PaginationWrapper'

export default {
    name: 'UnitUseDataModal',
    components: { PaginationWrapper },
    data() {
        return {
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },
            locale: '',
            groupingOptions: [
                { label: this.$t('unit.all'), value: '0' },
                { label: this.$t('unit.day'), value: 'day' },
                { label: this.$t('unit.week'), value: 'week' },
                { label: this.$t('unit.month'), value: 'month' },
            ],
            grouping: '0',
            refreshKey: 0,
        }
    },
    computed: {
        ...mapGetters({
            getLocale: 'user/getLocale',
        }),
        getRoute() {
            const routeName = 'unit.unit_use_data'
            return (page) => {
                return this.route(routeName, {
                    unit: this.$store.state.unit.unit.id,
                    page,
                    grouping: this.grouping,
                })
            }
        },
    },
    created() {
        this.locale = this.getLocale === 'en' ? en : de
    },
    destroyed() {
        this.resetPaginationState()
    },
    methods: {
        ...mapMutations({
            setCurrentPage: 'pagination/SET_CURRENT_PAGE',
            resetPaginationState: 'pagination/RESET_STATE',
        }),
        handleClose() {
            this.$emit('close-modal')
        },
        getBeginTime(value) {
            if (value !== undefined) {
                const dateObject = parseISO(value)
                const date = this.displayAsDate(dateObject)
                const time = format(dateObject, 'HH:mm')
                return `${date} ${this.$t('unit.at')} ${time}`
            }
            return ''
        },
        getDuration(begin, end) {
            if (begin !== undefined && end !== undefined) {
                const beginDate = parseISO(begin)
                const endDate = parseISO(end)
                return formatDistanceStrict(endDate, beginDate, {
                    locale: this.locale,
                })
            }
            return ''
        },
        handleGroupingSelect(value) {
            if (value !== this.grouping) {
                this.setCurrentPage({ currentPage: 1 })
                this.grouping = value
                this.refreshKey += 1
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.unit-use-data-modal-wrapper {
    padding: 0 40px 100px 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            margin: 0 auto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    .select-wrapper {
        margin: 100px 55px 20px 55px;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
