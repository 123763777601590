import * as Sentry from '@sentry/vue'
import { store } from '../store'

const getEnvironment = () => {
    const { host } = window.location
    const locations = {
        localhost: 'local-development',
        'uat.linxfour.com': 'development',
        'platform.linxfour.com': 'production',
    }
    return locations[host]
}

export function initializeSentry(Vue, router) {
    return Sentry.init({
        Vue,
        dsn: 'https://9681ed4a72094a5aa73df4543888ba82@o1154930.ingest.sentry.io/6235004',
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
            Sentry.breadcrumbsIntegration({
                console: false,
            }),
        ],
        // track all children components
        trackComponents: true,

        environment: getEnvironment(),

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.3,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        beforeSend(event, hint) {
            if (event.exception) {
                event.extra = {
                    ...event.extra,
                    vuexState: JSON.parse(JSON.stringify(store.state)),
                }
            }
            return event
        },
    })
}
