import axios from 'axios'
import * as Sentry from '@sentry/vue'

import router from '@/router'

import { store } from '../store'

// Request interceptor
axios.interceptors.request.use(
    (request) => {
        Sentry.addBreadcrumb({
            category: 'network',
            message: `Sending ${request.method} request to ${request.url}`,
            data: {
                url: request.url,
                method: request.method,
                data: request.data,
                headers: request.headers,
            },
        })

        return request
    },
    (error) => {
        Sentry.addBreadcrumb({
            category: 'network',
            message: `Error in request: ${error.message}`,
        })
        return Promise.reject(error)
    }
)

// Response interceptor
axios.interceptors.response.use(
    (response) => {
        Sentry.addBreadcrumb({
            category: 'network',
            message: `Response from ${response.config.url}`,
            data: {
                url: response.config.url,
                status: response.status,
                data: response.data,
            },
        })

        return response
    },
    (error) => {
        console.log(error)
        Sentry.addBreadcrumb({
            category: 'network',
            message: `Response error from ${error.config?.url}: ${error.message}`,
            data: {
                status: error.response?.status,
                data: error.response?.data,
            },
        })
        return Promise.reject(error)
    }
)

export const handleError = async (dispatch, error) => {
    dispatch('hideLoader', null, { root: true })
    let txt = `${error.message}`
    const errorsKeys = error.errors !== undefined ? Object.keys(error.errors) : []
    if (errorsKeys.length > 0) {
        txt = errorsKeys.reduce((holder, current) => {
            holder = `${holder}\n${error.errors[current]}`
            return holder
        }, txt)
    }
    const notification = {
        type: 'error',
        text: txt,
    }

    dispatch('notification/add', notification, { root: true })
}
export const refreshError = async (dispatch) => {
    dispatch('hideLoader', null, { root: true })
    const notification = {
        type: 'error-refresh',
        text: 'notifications.error_refresh',
    }

    dispatch('notification/add', notification, { root: true })
}

function ThrowError({ message, status }) {
    this.status = status
    this.message = message
}

// to detect csrf mismatch error
const catchError = (error) => {
    Sentry.captureException(error)

    // if there is csrf token error
    if (error.response.status === 419 || error.response.status === 401) {
        const notification = {
            type: 'error-re-login',
            text: 'ui.notification_text_error_re-login',
        }
        store.dispatch('notification/add', notification, { root: true })
        return
    }

    throw new ThrowError({
        status: error.response.status,
        message: error.response.data.message,
    })
}

export const apiGet = async (url) => {
    try {
        const response = await axios.get(url, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && (error.response.status === 403 || error.response.status === 404)) {
            router.push({ name: 'PageNotFound' })
            return null
        } else {
            catchError(error)
            throw error
        }
    }
}

export const apiPost = async (url, data) => {
    try {
        const response = await axios.post(url, data)
        return response.data
    } catch (error) {
        catchError(error)
    }
}

export const apiPatch = async (url, data) => {
    try {
        const response = await axios.patch(url, data)
        return response.data
    } catch (error) {
        catchError(error)
    }
}

export const apiPut = async (url, data) => {
    try {
        const response = await axios.put(url, data)
        return response.data
    } catch (error) {
        catchError(error)
    }
}

export const apiDownload = async (url, fileName, forcedExtension = null, method = 'POST') => {
    try {
        const response = await axios({
            method,
            url,
            responseType: 'blob',
        })

        // get filename set by the server
        const serverFileName = response.headers['content-disposition'].split('"')[1]

        const extension =
            forcedExtension !== null
                ? forcedExtension
                : response.headers['content-type'].split('/').reverse()[0]

        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${fileName}.${extension}`)
        document.body.appendChild(fileLink)

        fileLink.click()

        return serverFileName
    } catch (error) {
        catchError(error)
    }
}

export const apiDelete = async (url) => {
    try {
        const response = await axios.delete(url)
        return response.data
    } catch (error) {
        catchError(error)
    }
}

export const fetchUserData = async (apiRoute, dispatch, vm) => {
    try {
        const data = await apiGet(apiRoute)
        if (data === null) {
            return
        }

        const { lists, ...userData } = data
        return { lists, userData }
    } catch (error) {
        dispatch('hideLoader', null, { root: true })
        refreshError(dispatch, vm)
    }
}
