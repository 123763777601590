<template>
    <form
        class="form"
        @submit.prevent="handleSaveAndApprove"
    >
        <BaseInput
            class="mb-40"
            :value="$store.state.client.client.vat_identification_number"
            :validator="v$.vat_identification_number"
            @input="updateClient('vat_identification_number', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('client.vat_number') }}
                </BaseLabel>
            </template>
        </BaseInput>
        <div class="form__buttons">
            <BaseButton
                buttonClass="button--primary"
                :disabled="!isValid"
            >
                {{ $t('ui.button_save_and_approve') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
    name: 'AdditionalDataClientModal',
    data() {
        return {
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },

            saveSuccessAction: null,

            v$: {},
        }
    },

    computed: {
        ...mapState('client', { inputs: (state) => state.client }),

        isValid() {
            return !this.v$.$invalid
        },

        rules() {
            return {
                vat_identification_number: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(25),
                },
            }
        },
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.$store.state.client.client)
        this.handleData({ ...this.$store.state.modal.data })
    },

    methods: {
        ...mapActions({
            setVat: 'client/setVat',
            updateClientFields: 'client/updateClientFields',
        }),

        updateClient(name, value) {
            this.$store.commit('client/SET_CLIENT_INPUT', {
                name,
                value,
            })

            this.v$[name].$touch()
        },

        handleSaveAndApprove() {
            this.setVat({
                vm: this,
                saveSuccessAction: this.saveSuccessAction,
            })
        },

        setValid(value) {
            this.isValid = value.isValid
        },

        handleClose() {
            this.$emit('close-modal')
        },

        handleData(data) {
            this.saveSuccessAction = data.saveSuccessAction
        },
    },
}
</script>

<style lang="scss" scoped>
.form {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
