<template>
    <div
        v-if="showDragDrop"
        class="drag-drop-container"
    >
        <p
            v-if="showError"
            class="drag-drop-container__text drag-drop-container__text--error"
        >
            {{ $t(error) }}
        </p>
        <p
            v-else
            class="drag-drop-container__text"
            :class="disabled && 'drag-drop-container__text--disabled'"
        >
            {{ $t('ui.click_or_drop_file_to_upload') }}
        </p>
        <div
            v-if="disabled"
            class="drag-drop-area drag-drop-area--disabled"
            :uppy="uppy"
        />
        <DragDrop
            v-else
            class="drag-drop-area"
            :uppy="uppy"
        />
    </div>
    <div
        v-else
        class="drag-drop-file-display"
    >
        <div class="drag-drop-file-display__input">
            <BaseInput
                :value="fileName"
                :disabled="true"
            />
        </div>
        <div
            v-if="displayDownloadButton"
            class="drag-drop-file-display__icon"
            @click="downloadFile"
        >
            <mdicon
                class="icon--color-secondary"
                name="tray-arrow-down"
            />
        </div>
        <div
            class="drag-drop-file-display__icon drag-drop-file-display__icon--color-declined"
            @click="removeFile"
        >
            <mdicon
                class="icon--color-declined"
                name="tray-remove"
            />
        </div>
    </div>
</template>

<script>
import { DragDrop } from '@uppy/vue'
import '@uppy/core/dist/style.css'
import uppyInstance from '@/helpers/uppyInstance'

export default {
    name: 'DragAndDropUpload',

    components: { DragDrop },

    props: {
        fileId: {
            type: [String, Number],
            default: null,
        },
        fileName: {
            type: String,
            default: null,
        },
        filePath: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        uppy() {
            return uppyInstance
        },

        showDragDrop() {
            return this.fileId === null && this.filePath === ''
        },

        displayDownloadButton() {
            return !(this.filePath === '') && !(this.filePath === null)
        },

        showError() {
            return this.error.length > 0
        },
    },

    methods: {
        removeFile() {
            this.$emit('delete-file')
        },

        downloadFile() {
            this.$emit('download-file')
        },
    },
}
</script>

<style lang="scss">
.drag-drop-container {
    position: relative;
    height: 50px;
    margin-bottom: 50px;

    & .drag-drop-area {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: $cn;

        & .uppy-DragDrop-container {
            cursor: pointer;
        }
    }

    & .drag-drop-area--disabled {
        position: relative;
        z-index: 1;
        cursor: not-allowed;
        background-color: rgba($vc-gray, 0.3);
    }

    &__text {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cs;

        &--disabled {
            color: $vc-gray;
        }

        &--error {
            color: $vc-declined;
        }
    }

    div.uppy-Root {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-color: transparent;

        div.uppy-DragDrop-inner {
            display: none;
        }
    }
}

.drag-drop-file-display {
    display: flex;
    gap: 6px;
    cursor: pointer;

    &__input {
        flex: 1;
    }

    &__icon {
        width: 48px;
        height: 48px;
        margin-top: 4px;
        border: 2px solid $vc-secondary;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--color-declined {
            border: 2px solid $vc-declined;
        }
    }
}
</style>
