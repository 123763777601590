<template>
    <form
        class="authorized-person-modal"
        @submit.prevent="handleSave"
    >
        <BaseInput
            class="mb-40"
            v-model="v$.first_name.$model"
            :validator="v$.first_name"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('client.modal_authorized_person_first_name') }} *
                </BaseLabel>
            </template>
        </BaseInput>
        <BaseInput
            class="mb-40"
            v-model="last_name"
            :validator="v$.last_name"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('client.modal_authorized_person_last_name') }} *
                </BaseLabel>
            </template>
        </BaseInput>
        <BaseSelect
            class="mb-40"
            placeholder=""
            :options="genders"
            :value="gender"
            @input="setInput('gender', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('client.modal_authorized_person_gender') }} *
                </BaseLabel>
            </template>
        </BaseSelect>
        <BaseSelect
            class="mb-40"
            placeholder=""
            :options="countries"
            :value="country"
            @input="setInput('country', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('client.country') }} *
                </BaseLabel>
            </template>
        </BaseSelect>
        <BaseLabel
            :weight="labelsOptions.weight"
            :size="labelsOptions.size"
        >
            {{ $t('client.modal_authorized_person_passport') }}
        </BaseLabel>
        <!-- :savedFile="savedFile"
            :options="dragAndDropUploadOptions" -->
        <DragAndDropUpload
            v-if="showFileUpload"
            :fileName="fileName"
            :fileId="fileId"
            :filePath="savedFilePath"
            @delete-file="handleDeleteFile"
            @download-file="handleDownloadFile"
        />
        <div class="authorized-person-modal__buttons">
            <BaseButton
                buttonClass="button--primary"
                :disabled="isValid"
            >
                {{ $t('ui.button_save') }}
            </BaseButton>
            <BaseButton
                v-if="id != null"
                buttonClass="button--danger"
                @click.prevent="handleDeleteAuthPerson"
            >
                {{ $t('ui.delete') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import uppyInstance from '@/helpers/uppyInstance'
import DragAndDropUpload from '@/components/DragAndDropUpload'

const rules = {
    first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    gender: {
        required,
    },
    country: {
        required,
    },
}

export default {
    name: 'AuthorizedPersonModal',
    components: { DragAndDropUpload },
    data() {
        return {
            refreshKey: 0,
            showFileUpload: false,
            fileName: '',
            fileId: null,
            // filePath: '',

            genders: [
                {
                    value: 'm',
                    label: this.$t('client.modal_authorized_person-male'),
                },
                {
                    value: 'w',
                    label: this.$t('client.modal_authorized_person-female'),
                },
                {
                    value: 'x',
                    label: this.$t('client.modal_authorized_person-other'),
                },
            ],
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },

            first_name: '',
            last_name: '',
            gender: '',
            country: '',

            savedFilePath: '',

            id: null,
            saveSuccessAction: null,
            v$: useVuelidate(rules, this),
        }
    },

    computed: {
        ...mapGetters({
            can: 'user/can',
        }),
        isValid() {
            return this.v$.$invalid
        },

        uppy() {
            return uppyInstance
        },
        getFormData() {
            return {
                first_name: this.first_name,
                last_name: this.last_name,
                gender: this.gender,
                country_id: this.country,
                client_id: this.$store.state.client.client.id,
            }
        },
        countries() {
            return this.$store.state.country.countryOptions
        },
        savedFile() {
            return {
                path: this.savedFilePath,
            }
        },
        isAdmin() {
            return this.can('admin')
        },
        dragAndDropUploadOptions() {
            return {
                downloadButton: {
                    show: this.isAdmin,
                },
            }
        },
    },
    created() {
        // uppy
        this.uppy.on('file-added', this.handleFileAdded)
    },

    mounted() {
        this.handleData(this.$store.state.modal.data)

        this.uppy.setOptions({
            restrictions: {
                allowedFileTypes: ['.pdf', 'image/*'],
            },
        })

        if (this.savedFile.path !== '') {
            this.fileName = this.$t('client.passport')
        }
    },

    beforeDestroy() {
        // uppy
        this.uppy.off('file-added')

        const addedFiles = this.uppy.getFiles()
        addedFiles.forEach((file) => this.uppy.removeFile(file.id))
        this.uppy.close({ reason: 'unmount' })
    },
    methods: {
        ...mapActions({
            saveAuthPerson: 'client/saveAuthPerson',
            updateAuthPerson: 'client/updateAuthPerson',
            deleteAuthPerson: 'client/deleteAuthPerson',
            deletePassport: 'client/deletePassport',
            downloadPassport: 'client/downloadPassport',
        }),
        handleSave() {
            if (this.id == null) {
                this.saveAuthPerson({
                    vm: this,
                    formData: this.getFormData,
                    saveSuccessAction: this.saveSuccessAction || null,
                    uppy: this.uppy,
                })
            } else {
                this.updateAuthPerson({
                    vm: this,
                    personId: this.id,
                    formData: this.getFormData,
                    uppy: this.uppy,
                })
            }
        },
        setValid(value) {
            this.isValid = value.isValid
        },

        handleData(data) {
            this.id = data.id || null
            this.first_name = data.first_name || ''
            this.last_name = data.last_name || ''
            this.gender = data.gender
            this.country = data.country_id
            this.savedFilePath = data.passport_file_path || ''

            if (this.savedFilePath !== '') {
                this.fileName = this.$t('client.passport')
            }

            this.saveSuccessAction = data.saveSuccessAction
            this.showFileUpload = true
        },
        handleDeleteAuthPerson() {
            this.deleteAuthPerson({
                vm: this,
                personId: this.id,
                saveSuccessAction: this.saveSuccessAction,
            })
        },
        // set gender from the dropdown
        setInput(field, value) {
            this[field] = value
        },
        // when modal is loaded with data, gender is a value
        getObjectFromOptions(optionsObjectName, savedValue = '') {
            const gender = [
                ...this[optionsObjectName].filter((obj) => {
                    const value = obj.value === undefined ? obj.key : obj.value
                    return value === savedValue
                }),
            ]
            return gender.length === 1 ? gender[0] : ''
        },

        async handleDeleteFile() {
            this.showFileUpload = false
            this.removeExistingSelectedFile()
            await this.deletePassport({ vm: this, personId: this.id })
            this.savedFilePath = ''
            this.showFileUpload = true
        },

        handleDownloadFile() {
            this.downloadPassport({
                vm: this,
                personId: this.id,
                last_name: this.last_name,
                first_name: this.first_name,
            })
        },

        // uppy file methods
        removeExistingSelectedFile() {
            if (this.fileId !== null) {
                this.uppy.removeFile(this.fileId)
                this.fileId = null
                this.fileName = null
            }
        },

        handleFileAdded(file) {
            this.fileId = file.id
            this.fileName = this.$t('client.passport')
        },
    },
}
</script>

<style lang="scss">
.authorized-person-modal {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;

        & button:first-child {
            margin-right: 10px;
        }
    }
}
</style>
