const getNumber = (precision) => {
    const str = '1'
    return Number(str.padEnd(precision + 1, 0))
}

export const toFloat = (precision) => (value) => {
    if (value === '') return ''
    return Number((Number(value) / getNumber(precision)).toFixed(precision))
}

export const toInt = (precision) => (value) => {
    if (value === null) return ''
    return Math.round(Number(value) * getNumber(precision))
}

export const flatenObject = (initial) => {
    const valuePropName = initial.hasOwnProperty('key') ? 'key' : 'value'
    const value =
        typeof initial === 'object' && initial[valuePropName] !== 'undefined'
            ? initial[valuePropName]
            : initial
    return value
}

// actions [[nameOfPropsToProccess], function]
export const normalize = (data, actions) => {
    const dataCopy = { ...data }

    actions.forEach((action) => {
        action[0].forEach((key) => {
            dataCopy[key] = action[1](dataCopy[key])
        })
    })

    return dataCopy
}
