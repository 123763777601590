import XHRUpload from '@uppy/xhr-upload'
import {
    apiPost,
    apiGet,
    apiPatch,
    apiDelete,
    apiDownload,
    handleError,
    refreshError,
} from '@/helpers/api'
import { normalize, flatenObject } from '@/helpers/normalize'

const namespaced = true

const DEFAULT_CLIENT_STATE = {
    id: null,
    name: '',
    vat_identification_number: '',
    company_registration_number: '',
    country_id: '',
    enduser_industry_id: '',
    street: '',
    postal_code: '',
    city: '',
    contact_person: '',
    contact_email: '',

    invoice_email: '',
    maintenance_email: '',

    phone: '',
    authorized_people: [],
    removable: false,
}

const state = () => ({
    client: { ...DEFAULT_CLIENT_STATE },
    inputs: { ...DEFAULT_CLIENT_STATE },
    countries: [],
    industries: [],
    objectFields: ['country_id', 'enduser_industry_id'],
    clientOptions: [],
})

const mutations = {
    SET_CLIENT_INPUT(state, { name, value }) {
        Vue.set(state.client, name, value)
    },

    SET_INPUT(state, { name, value }) {
        state.inputs[name] = value
    },

    RESET_INPUTS(state) {
        state.inputs = { ...DEFAULT_CLIENT_STATE }
    },

    UPDATE_FORM_STATE(state, { newState }) {
        state.client = newState
    },

    UPDATE_INDUSTRIES_OPTIONS(state, { options }) {
        state.industries = [...options]
    },

    RESET_FORM_STATE(state) {
        state.client = { ...DEFAULT_CLIENT_STATE }
    },

    UPDATE_AUTH_PEOPLE_ARRAY(state, authorized_people) {
        state.client.authorized_people = [...authorized_people]
    },
}

export const actions = {
    resetFormState({ commit }) {
        commit('RESET_FORM_STATE')
    },

    async openAuthorizedPersonModal({ commit }, data = null) {
        const title = data.id
            ? 'client.modal_authorized_person_create'
            : 'client.modal_authorized_person_edit'
        commit(
            'modal/SET_OPEN',
            {
                name: 'AuthorizedPersonModal',
                size: 'small',
                title,
                data: data === null ? {} : { ...data },
            },
            { root: true }
        )
    },

    async save({ state, dispatch, commit }, { vm }) {
        try {
            const { name } = state.inputs

            const data = await apiPost(vm.route('client.store'), {
                ...state.inputs,
            })

            commit('SET_INPUT', {
                name: 'id',
                value: data.client_id,
            })

            dispatch(
                'addItemToViewVars',
                {
                    listName: 'client',
                    item: { value: data.client_id, label: name },
                    vm,
                },
                { root: true }
            )

            return data
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async saveClient({ state, dispatch, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const normalizedData = normalize({ ...state.client }, [
                [state.objectFields, flatenObject],
            ])
            const data = await apiPost(vm.route('client.store'), {
                ...normalizedData,
            })
            dispatch('hideLoader', null, { root: true })

            // add new client to the view vars state and local storage
            dispatch(
                'addItemToViewVars',
                {
                    listName: 'client',
                    item: { value: data.client_id, label: state.client.name },
                    vm,
                },
                { root: true }
            )

            commit('pagination/DELETE_CURRENT_AND_LAST_PAGE_CONTENT', null, {
                root: true,
            })

            return data.client_id
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async saveClientModal(
        { state, dispatch, commit },
        { vm, successCommitObject }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiPost(vm.route('client.store'), {
                ...state.client,
            })
            dispatch('hideLoader', null, { root: true })

            // add new client to the view vars state and local storage
            dispatch(
                'addItemToViewVars',
                {
                    listName: 'client',
                    item: { value: data.client_id, label: state.client.name },
                    vm,
                },
                { root: true }
            )

            const payload = successCommitObject.data(data.client_id)

            commit(successCommitObject.mutation, payload, {
                root: true,
            })
            commit('modal/SET_CLOSE', null, { root: true })
            commit('RESET_FORM_STATE')
        } catch (error) {
            commit('RESET_FORM_STATE')
            commit('modal/SET_CLOSE', null, { root: true })
            handleError(dispatch, error)
        }
    },

    async updateClient({ dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const normalizedData = normalize({ ...state.client }, [
                [state.objectFields, flatenObject],
            ])
            const response = await apiPatch(
                vm.route('client.update', [state.client.id]),
                normalizedData
            )
            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: state.client.name,
                    }),
                }

                // update client label in the view vars state and local storage
                dispatch(
                    'updateItemInViewVars',
                    {
                        listName: 'client',
                        item: {
                            value: state.client.id,
                            label: state.client.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updateClientModal({ dispatch, state }, { vm, saveSuccessAction }) {
        try {
            dispatch('showLoader', null, { root: true })
            commit('modal/SET_CLOSE', null, { root: true })
            const normalizedData = normalize({ ...state.client }, [
                [state.objectFields, flatenObject],
            ])
            const response = await apiPatch(
                vm.route('client.update', [state.client.id]),
                { ...normalizedData, ajax: true }
            )

            if (response.status === 'success') {
                saveSuccessAction()
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async getItemData({ dispatch, state, rootState, commit }, { id, vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(vm.route('client.show', [id]))
            dispatch('hideLoader', null, { root: true })

            const client = { ...data.address, ...data }
            delete client.address

            Object.keys(client).forEach((el) => {
                commit('SET_CLIENT_INPUT', {
                    name: el,
                    value: client[el],
                })
            })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async handleDelete({ dispatch, commit, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiDelete(
                vm.route('client.destroy', { client: state.client.id })
            )

            commit('modal/SET_CLOSE', null, { root: true })

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_deleted', {
                        name: state.client.name,
                    }),
                }

                dispatch('notification/add', notification, { root: true })

                // remove client item in the view vars state and local storage
                dispatch(
                    'removeItemFromViewVars',
                    {
                        listName: 'client',
                        item: {
                            value: state.client.id,
                            label: state.client.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                commit('RESET_FORM_STATE')
                commit(
                    'pagination/DELETE_CURRENT_AND_LAST_PAGE_CONTENT',
                    null,
                    { root: true }
                )
                vm.$router.push({
                    name: 'ClientIndex',
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async requestKycnowReport({ dispatch, commit, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('client.kycnow', state.client.id)
            )
            dispatch('hideLoader', null, { root: true })
            // TODO: show notification that report has been requested
        } catch (error) {
            handleError(dispatch, error)
        }
    },
    async requestDnbFileReport({ dispatch, commit, state }, { client_id, vm }) {
        window.location = vm.route('client.dnb_file_report', {
            client: client_id,
        })
    },

    // check does client has vat and at least one auth person
    // used in the click and finance to save the contract
    async checkClientData(
        { state, dispatch, commit },
        { vm, clientId, dataIsValidActionName }
    ) {
        try {
            await dispatch('getItemData', { id: clientId, vm })

            if (state.client.vat_identification_number === null) {
                dispatch('hideLoader', null, { root: true })

                commit(
                    'modal/SET_OPEN',
                    {
                        name: 'AdditionalDataClientModal',
                        size: 'small',
                        title: 'client.modal_additional_data_header',
                        data: {
                            vm,
                            clientId,
                            saveSuccessAction: dataIsValidActionName,
                        },
                    },
                    { root: true }
                )

                return
            }

            if (state.client.authorized_people.length === 0) {
                dispatch('hideLoader', null, { root: true })

                commit(
                    'modal/SET_OPEN',
                    {
                        name: 'AuthorizedPersonModal',
                        size: 'small',
                        title: 'client.modal_authorized_person_create',
                        data: {
                            vm,
                            clientId,
                            saveSuccessAction: dataIsValidActionName,
                        },
                    },
                    { root: true }
                )

                return
            }

            dispatch('showLoader', null, { root: true })
            commit('RESET_FORM_STATE')
            dispatch(dataIsValidActionName, { vm }, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async setVat({ dispatch, state, commit }, { vm, saveSuccessAction }) {
        try {
            dispatch('showLoader', null, { root: true })
            commit('modal/SET_CLOSE', null, { root: true })

            const response = await apiPatch(
                vm.route('client.set_vat', [state.client.id]),
                {
                    vat_identification_number:
                        state.client.vat_identification_number,
                }
            )

            if (response.status === 'success') {
                dispatch('checkClientData', {
                    vm,
                    clientId: state.client.id,
                    dataIsValidActionName: saveSuccessAction,
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    // Authorized person actions
    async saveAuthPerson(
        { dispatch, state, commit },
        { vm, formData, saveSuccessAction = null, uppy }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            if (uppy.getFiles().length === 0) {
                const client_id = state.client.id
                const data = { ...formData, client_id }
                commit('modal/SET_CLOSE', null, { root: true })
                const response = await apiPost(
                    vm.route('authorized_person.store'),
                    data
                )

                if (response.status === 'success') {
                    if (saveSuccessAction === null) {
                        const person = { id: response.id, ...formData }
                        const authorized_people = [
                            ...state.client.authorized_people,
                            person,
                        ]
                        commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
                    } else {
                        dispatch('checkClientData', {
                            vm,
                            clientId: client_id,
                            dataIsValidActionName: saveSuccessAction,
                        })
                    }
                }

                dispatch('hideLoader', null, {
                    root: true,
                })
            } else {
                const client_id = state.client.id
                uppy.setMeta({ ...formData, client_id })

                const headers = {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
                        'content'
                    ),
                }

                uppy.use(XHRUpload, {
                    endpoint: vm.route('authorized_person.store'),
                    method: 'post',
                    headers,
                    formData: true,
                    fieldName: 'passport',
                    allowedMetaFields: null,
                })

                uppy.upload()

                uppy.on('upload-success', (file, response) => {
                    if (saveSuccessAction === null) {
                        const person = {
                            id: response.body.id,
                            passport_file_path:
                                response.body.passport_file_path,
                            ...formData,
                        }
                        const authorized_people = [
                            ...state.client.authorized_people,
                            person,
                        ]
                        commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
                    } else {
                        dispatch('checkClientData', {
                            vm,
                            clientId: client_id,
                            dataIsValidActionName: saveSuccessAction,
                        })
                    }

                    commit('modal/SET_CLOSE', null, { root: true })

                    dispatch('hideLoader', null, { root: true })
                })

                uppy.on('error', (er) => {
                    dispatch('hideLoader', null, {
                        root: true,
                    })
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updateAuthPerson(
        { dispatch, commit, state },
        { vm, formData, personId, uppy }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            if (uppy.getFiles().length === 0) {
                const client_id = state.client.id
                const data = { ...formData, client_id }
                const response = await apiPost(
                    vm.route('authorized_person.update', [personId]),
                    data
                )

                if (response.status === 'success') {
                    const authorized_people =
                        state.client.authorized_people.map((person) => {
                            if (person.id === personId) {
                                person.first_name = formData.first_name
                                person.last_name = formData.last_name
                                person.gender = formData.gender
                                person.country_id = formData.country_id
                            }
                            return person
                        })
                    commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
                }

                commit('modal/SET_CLOSE', null, { root: true })
                dispatch('hideLoader', null, {
                    root: true,
                })
            } else {
                uppy.setMeta({ ...formData, client_id: state.client.id })

                const headers = {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
                        'content'
                    ),
                }

                uppy.use(XHRUpload, {
                    endpoint: vm.route('authorized_person.update', [personId]),
                    method: 'post',
                    headers,
                    formData: true,
                    fieldName: 'passport',
                    allowedMetaFields: null,
                })

                uppy.upload()

                uppy.on('upload-success', (file, response) => {
                    const authorized_people =
                        state.client.authorized_people.map((person) => {
                            if (person.id === personId) {
                                person.first_name = formData.first_name
                                person.last_name = formData.last_name
                                person.gender = formData.gender
                                person.country_id = formData.country_id
                                person.passport_file_path =
                                    response.body.passport_file_path
                            }
                            return person
                        })
                    commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
                    commit('modal/SET_CLOSE', null, { root: true })
                    dispatch('hideLoader', null, { root: true })
                })

                uppy.on('error', (er) => {
                    dispatch('hideLoader', null, {
                        root: true,
                    })
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },
    async deleteAuthPerson({ dispatch, state, commit }, { vm, personId }) {
        try {
            dispatch('showLoader', null, { root: true })
            commit('modal/SET_CLOSE', null, { root: true })
            const response = await apiDelete(
                vm.route('authorized_person.destroy', [personId])
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                const authorized_people = state.client.authorized_people.filter(
                    (person) => {
                        if (person.id !== personId) {
                            return person
                        }
                    }
                )
                commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async downloadPassport(
        { dispatch },
        { vm, personId, last_name, first_name }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            const FILE_NAME = `${first_name} ${last_name} passport`
            await apiDownload(
                vm.route('authorized_person_passport.download', [personId]),
                FILE_NAME
            )
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async deletePassport({ dispatch, state, commit }, { vm, personId }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiDelete(
                vm.route('authorized_person_passport.destroy', [personId])
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                const authorized_people = state.client.authorized_people.map(
                    (person) => {
                        if (person.personId !== personId) {
                            person.passport_file_path = ''
                        }
                        return person
                    }
                )
                commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
