<template>
    <p class="main-content-no-resources">
        {{ $t('ui.no_items_text') }}
    </p>
</template>

<script>
export default {
    name: 'MainContentNoResources',
}
</script>

<style lang="scss" scoped>
.main-content-no-resources {
    color: $vc-primary;
    font-size: 18px;
    padding: 15px 50px;
}
</style>
