<template>
    <form class="form" @submit.prevent="handleSave">
        <ClientItem
            :isAuthPersonShown="false"
            cssClass="main-content"
            :includeAdvancedOptions="false"
            :labelsOptions="labelsOptions"
            @validityChange="setValid"
        />
        <div class="form__buttons">
            <BaseButton buttonClass="button--primary" :disabled="!isValid">
                {{ $t('ui.button_save') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import { mapActions } from 'vuex'
import ClientItem from './ClientItem'

export default {
    name: 'SimpleClientModal',
    components: { ClientItem },
    data() {
        return {
            isValid: false,
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },
        }
    },

    methods: {
        ...mapActions({
            saveClientModal: 'client/saveClientModal',
        }),

        handleSave() {
            this.saveClientModal({
                vm: this,
                successCommitObject:
                    this.$store.state.modal.data.successCommitObject,
            })
        },

        setValid(value) {
            this.isValid = value.isValid
        },
    },
}
</script>

<style lang="scss" scoped>
.form {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        margin-top: 100px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
