import XHRUpload from '@uppy/xhr-upload'
import { apiGet, apiDownload, refreshError, handleError } from '@/helpers/api'

const namespaced = true

export const InvoiceStageEnum = {
    CREATED: 'created',
    DISPATCHED: 'dispatched',
    REMINDER: 'reminder',
    WARNING_1: 'warning_1',
    WARNING_2: 'warning_2',
    DEBT_COLLECTION: 'debt_collection',
    PAID: 'paid',
    CANCELED: 'canceled',
};

const state = () => ({
    invoiceViewData: {},
})

const mutations = {
    SET_INVOICE_VIEW_DATA(state, data) {
        state.invoiceViewData = { ...data }
    },
    RESET_INVOICE_VIEW_DATA(state) {
        state.invoiceViewData = {}
    },
}

export const actions = {

    async fetchInvoiceData({ dispatch, commit }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(vm.route('invoices.show', { invoice: id }))
            if (data === null) {
                return
            }
            commit('SET_INVOICE_VIEW_DATA', data)
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async generateInvoicesForContract({ dispatch, commit }, { vm, contractId }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(vm.route('contract.invoices.generate', { contract: contractId }))
            if (data === null) {
                return
            }
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async downloadInvoiceFile({ dispatch, state }, { vm, invoiceId, fileId, fileName = 'downloaded_file' }) {
        try {
            dispatch('showLoader', null, { root: true })

            const url = vm.route('invoices.downloadFile', { invoice: invoiceId, file: fileId });
            await apiDownload(url, fileName, 'pdf', 'GET');

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async markAsPaid({ dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const { id } = state.invoiceViewData

            await apiGet( vm.route('invoices.markAsPaid', { invoice: id }) )

            dispatch('hideLoader', null, { root: true })
            await dispatch('fetchInvoiceData', { vm, id })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async moveToNextStage({ dispatch, state, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const { id } = state.invoiceViewData

            await apiGet( vm.route('invoices.nextStage', { invoice: id }) )

            dispatch('hideLoader', null, { root: true })
            await dispatch('fetchInvoiceData', { vm, id })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async cancelInvoice({ dispatch, state, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const { id } = state.invoiceViewData

            await apiGet( vm.route('invoices.cancel', { invoice: id }) )

            dispatch('hideLoader', null, { root: true })
            await dispatch('fetchInvoiceData', { vm, id })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    uploadInvoice({ dispatch, commit }, { vm, uppy, contract, formData }) {
        dispatch('showLoader', null, { root: true })

        const headers = {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
        }

        uppy.setMeta({ ...formData })

        uppy.use(XHRUpload, {
            endpoint: vm.route('invoices.upload', {
                contract,
            }),
            method: 'post',
            headers,
            formData: true,
            fieldName: 'file',
            allowedMetaFields: null,
        })

        uppy.upload()

        uppy.on('upload-success', (file, response) => {
            dispatch('hideLoader', null, { root: true })

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_uploaded'),
            }

            dispatch('notification/add', notification, { root: true })

            const contractInvoicesViewName = 'ContractInvoices'

            if (vm.$route.name === contractInvoicesViewName) {
                vm.$router.go()
            }

        })

        uppy.on('upload-error', (file, error, response) => {
            commit('modal/SET_CLOSE', null, { root: true })
            dispatch('hideLoader', null, {
                root: true,
            })
            handleError(dispatch, { message: response.body.error })
        })
    },
}

const getters = {
    getPropValue: (state) => (propName) => state.invoiceViewData[propName],
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
