<template>
    <div
        class="confirm-action-modal-wrapper"
        @submit.prevent="handleSaveAndApprove"
    >
        <div
            v-if="showComponentSection"
            class="confirm-action-modal-wrapper__components-wrapper"
        >
            <component :is="component" />
        </div>

        <div class="confirm-action-modal-wrapper__buttons">
            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="handleClose"
            />

            <CoreButtonPrimary
                ref="confirm-action-button"
                @click="handleExecuteAction"
                :label="buttonLabel"
                icon="pi-arrow-up-right"
            />
        </div>
    </div>
</template>

<script>
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'

const TYPE_CONFIRM = 'confirm'
const TYPE_DELETE = 'delete'

export default {
    name: 'ConfirmActionModal',

    components: { CoreButtonPrimary, CoreButtonSecondary },

    data() {
        return {
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },

            type: '',

            heading: '',

            action: TYPE_CONFIRM,

            buttonLabel: '',

            component: null,

            propsToPass: {},
        }
    },

    computed: {
        buttonClass() {
            if (this.type === TYPE_DELETE) return 'button--danger'

            return 'button--primary'
        },

        translatedHeading() {
            return this.heading === ''
                ? this.$t('ui.please_confirm_before_proceeding')
                : this.$t(this.heading)
        },

        translatedButtonLabel() {
            return this.buttonLabel === ''
                ? this.$t('ui.button_confirm')
                : this.$t(this.buttonLabel)
        },
    },

    mounted() {
        this.handleData({ ...this.$store.state.modal.data })
        this.$refs['confirm-action-button'].$el.focus()
    },

    methods: {
        handleData({
            type,
            heading,
            buttonLabel,
            action,
            component,
            propsToPass,
        }) {
            this.type = type
            this.heading = heading
            this.buttonLabel = buttonLabel
            this.action = action
            this.component = component
            this.propsToPass = propsToPass
        },

        handleClose() {
            this.$emit('close-modal')
        },

        handleExecuteAction() {
            this.$store.dispatch(this.action, {
                vm: this,
                propsToPass: this.propsToPass,
            })
            this.handleClose()
        },

        showComponentSection() {
            return this.component !== null
        },
    },
}
</script>

<style lang="scss" scoped>
.confirm-action-modal-wrapper {
    padding: 40px;

    &__components-wrapper {
        margin-bottom: 40px;
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 12px;
    }
}
</style>
