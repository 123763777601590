<template>
    <div>
        <div :class="cssClass">
            <BaseInput
                :errors="errors.name"
                :value="$store.state.client.client.name"
                :validator="v$.name"
                @input="updateClient('name', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.company_name') }}
                        {{ isFieldRequired('name') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.vat_identification_number"
                :value="$store.state.client.client.vat_identification_number"
                :validator="v$.vat_identification_number"
                @input="updateClient('vat_identification_number', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.vat_number') }}
                        {{ isFieldRequired('vat_identification_number') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.company_registration_number"
                :value="$store.state.client.client.company_registration_number"
                :validator="v$.company_registration_number"
                @input="updateClient('company_registration_number', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.company_registration_number') }}
                        {{ isFieldRequired('company_registration_number') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseSelect
                :placeholder="$t('client.industry_placeholder')"
                :options="this.$store.state.industry.endUserIndustryOptions"
                :value="$store.state.client.client.enduser_industry_id"
                @input="updateClient('enduser_industry_id', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.industry') }}
                        {{ isFieldRequired('enduser_industry_id') }}
                    </BaseLabel>
                </template>
            </BaseSelect>

            <BaseSelect
                :placeholder="$t('client.country_placeholder')"
                :options="countries"
                :value="$store.state.client.client.country_id"
                @input="updateClient('country_id', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.country') }}
                        {{ isFieldRequired('country_id') }}
                    </BaseLabel>
                </template>
            </BaseSelect>

            <BaseInput
                :value="$store.state.client.client.city"
                :validator="v$.city"
                @input="updateClient('city', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.city') }}
                        {{ isFieldRequired('city') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :value="$store.state.client.client.postal_code"
                :validator="v$.postal_code"
                @input="updateClient('postal_code', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.postal_code') }}
                        {{ isFieldRequired('postal_code') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :value="$store.state.client.client.street"
                :validator="v$.street"
                @input="updateClient('street', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.street') }}
                        {{ isFieldRequired('street') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.contact_person"
                :value="$store.state.client.client.contact_person"
                :validator="v$.contact_person"
                @input="updateClient('contact_person', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.contact_person') }}
                        {{ isFieldRequired('contact_person') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.contact_email"
                :value="$store.state.client.client.contact_email"
                :validator="v$.contact_email"
                @input="updateClient('contact_email', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.email') }}
                        {{ isFieldRequired('contact_email') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.invoice_email"
                :value="$store.state.client.client.invoice_email"
                :validator="v$.invoice_email"
                @input="updateClient('invoice_email', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.invoice_email') }}
                        {{ isFieldRequired('invoice_email') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.maintenance_email"
                :value="$store.state.client.client.maintenance_email"
                :validator="v$.maintenance_email"
                @input="updateClient('maintenance_email', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.maintenance_email') }}
                        {{ isFieldRequired('maintenance_email') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseInput
                :errors="errors.phone"
                :value="$store.state.client.client.phone"
                :validator="v$.phone"
                @input="updateClient('phone', $event)"
            >
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('client.phone') }}
                        {{ isFieldRequired('phone') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <div
                v-if="isAuthPersonShown"
                class="full-width person-table"
            >
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ authorizePersonHeading }}
                </BaseLabel>
                <br />

                <AuthorizedPeopleSection :authorizedPeople="authorizedPeople" />
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import AuthorizedPeopleSection from '@/components/AuthorizedPeopleSection'

const rules = {
    name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    vat_identification_number: {
        maxLength: maxLength(25),
    },
    company_registration_number: {
        maxLength: maxLength(25),
    },
    country_id: {
        required,
    },
    enduser_industry_id: {
        required,
    },
    billing_address: {
        maxLength: maxLength(100),
    },
    street: {
        required,
        maxLength: maxLength(200),
    },
    postal_code: {
        required,
        maxLength: maxLength(32),
    },
    city: {
        required,
        maxLength: maxLength(100),
    },
    contact_person: {
        required,
        maxLength: maxLength(100),
    },
    contact_email: {
        required,
        email,
    },
    invoice_email: { email },
    maintenance_email: { email },
    phone: {},
}

export default {
    name: 'ClientItem',

    components: { AuthorizedPeopleSection },

    props: {
        cssClass: {
            type: String,
            default: 'modal-grid',
        },

        isAuthPersonShown: {
            type: Boolean,
            required: true,
        },

        labelsOptions: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            errors: {},
            v$: {},
            name: '',
        }
    },

    computed: {
        countries() {
            return this.$store.state.country.countryFinancingAvailableOptions
        },

        isValid() {
            return !this.v$.$invalid
        },

        authorizePersonHeading() {
            return this.$store.state.client.client.authorized_people.length > 1
                ? this.$t('client.authorized_persons')
                : this.$t('client.authorized_person')
        },

        authorizedPeople() {
            return this.$store.state.client.client.authorized_people
        },
    },

    watch: {
        isValid() {
            this.$emit('validityChange', { isValid: this.isValid })
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules, this.$store.state.client.client)
            if (this.isValid) {
                this.$emit('validityChange', { isValid: this.isValid })
            }
        })
    },

    methods: {
        updateClient(name, value) {
            this.$store.commit('client/SET_CLIENT_INPUT', { name, value })

            this.v$[name].$touch()
        },
    },
}
</script>

<style computed lang="scss">
.modal-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

    grid-auto-flow: row;
}

.person-table {
    margin-bottom: 80px;

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
    }
}

.table-button {
    margin-left: auto;
}

.button-with-icon {
    position: relative;

    .plus::before {
        content: '';
        width: 3px;
        height: 13px;

        position: absolute;
        left: 7px;
        top: 10px;
        background-color: $vc-secondary;
    }

    .plus::after {
        content: '';
        width: 13px;
        height: 2px;

        position: absolute;
        left: 2px;
        top: 16px;
        background-color: $vc-secondary;
    }
}
</style>
