<template>
    <div class="pagination-wrapper">
        <div v-if="showNoItems">
            <MainContentNoResources />
        </div>
        <div v-else-if="showTableSkeleton">
            <SkeletonTable />
        </div>
        <div v-else-if="getPages(currentPage) !== null">
            <slot
                name="content"
                :rows="getPages(currentPage)"
            />
            <div class="buttons">
                <div
                    v-if="lastPage > 1"
                    class="buttons__table"
                >
                    <BaseButton
                        v-if="currentPage > 2"
                        buttonClass="button--primary"
                        @click="getPage(1)"
                    >
                        &ltrif; &ltrif;
                    </BaseButton>
                    <BaseButton
                        v-if="currentPage > 1"
                        buttonClass="button--primary"
                        @click="getPage(currentPage - 1)"
                    >
                        &ltrif;
                    </BaseButton>

                    <div class="page-indicator">
                        {{ currentPage }} |
                        {{ lastPage }}
                    </div>

                    <BaseButton
                        v-if="currentPage < lastPage"
                        buttonClass="button--primary"
                        @click="getPage(currentPage + 1)"
                    >
                        &rtrif;
                    </BaseButton>
                    <BaseButton
                        v-if="currentPage < lastPage - 1"
                        buttonClass="button--primary"
                        @click="getPage(lastPage)"
                    >
                        &rtrif; &rtrif;
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import MainContentNoResources from './MainContentNoResources.vue'
import SkeletonTable from './SkeletonTable.vue'

export default {
    name: 'PaginationWrapper',
    components: { MainContentNoResources, SkeletonTable },
    props: {
        getRoute: {
            type: Function,
            required: true,
        },
        forceRefetch: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            getPages: 'pagination/getPage',
        }),
        ...mapState('pagination', ['lastPage', 'currentPage', 'queryString', 'fetching', 'pages']),
        isFirstPageEmpty() {
            return this.pages['1'] && this.pages['1'].length === 0
        },
        noPages() {
            return Object.keys(this.pages).length === 0 || this.isFirstPageEmpty
        },
        showNoItems() {
            return this.noPages && !this.fetching
        },
        showTableSkeleton() {
            return this.fetching
        },
    },
    watch: {
        queryString() {
            this.getPage(1, true)
        },
    },
    mounted() {
        this.getPage(this.currentPage)
    },
    methods: {
        ...mapActions({
            fetchPage: 'pagination/fetchPage',
        }),
        ...mapMutations({
            setCurrentPage: 'pagination/SET_CURRENT_PAGE',
        }),
        async getPage(page, localForceRefetch = false) {
            const success = await this.fetchPage({
                page,
                routeName: `${this.getRoute(page)}${this.queryString}`,
                forceRefetch: this.forceRefetch || localForceRefetch,
                vm: this,
            })
            if (success) this.setCurrentPage({ currentPage: page })
        },
    },
}
</script>

<style lang="scss" scoped>
.pagination-wrapper {
    position: relative;
    padding-bottom: 100px;
}

.buttons {
    margin: 50px 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    button:not(last-child) {
        margin-right: 12px;
    }
}
.page-indicator {
    display: inline-block;
    margin-right: 12px;
    padding: 5px 14px;
    border-radius: 20px;

    font-size: 20px;
    font-weight: 300;
    line-height: 23px;
    border: 1px solid $cs;
    background: transparent;

    color: $cs;
}
</style>
